import './Aboutpage.css'
import Prem1 from './../../img/adv_1.png'
import Prem2 from './../../img/adv_it2.svg'
import Prem3 from './../../img/adv_it3.svg'
import License from './../../img/license.png'
import whu1 from './../../img/whuis1.png'
import whu2 from './../../img/whuis2.png'
import whu3 from './../../img/whuis3.png'
import lab1 from './../../img/lab1.png'
import lab2 from './../../img/lab2.png'
import lab3 from './../../img/lab3.png'
import slid1 from './../../img/slider/slid1.png'
import slid2 from './../../img/slider/slid2.png'
import slid3 from './../../img/slider/slid3.png'
import slid4 from './../../img/slider/slide4.jpg'
import slid5 from './../../img/slider/slide5.jpg'
import next from './../../img/icon/next.svg'
import prev from './../../img/icon/prew.svg'
import text from './../../img/text.png'
import brend from './../../img/brend.png'
import video from '../../video/video.mp4'
import video__lab from '../../video/video_lab.mp4'
import get_word from '../../img/pol.png'
import video_poster from '../../img/video_poster.png'
import ModalMessage from '../../components/modalMessage/ModalMessage'

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import 'swiper/css'
import PhoneInput from '@src/components/phoneInput/PhoneInput'

const About = () => {
	const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 900)
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
	const [isMessageFail, setIsMessageFail] = useState(false)
	const [isMessageSuccess, setIsMessageSuccess] = useState(false)
	const [formData, setFormData] = useState({
		name: '',
		phone_number: '',
	})

	const handleResize = () => {
		setIsWideScreen(window.innerWidth > 900)
	}

	const handleCheckboxChange = (e) => {
		setIsCheckboxChecked(e.target.checked)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData({ ...formData, [name]: value })
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		if (isCheckboxChecked) {
			fetch(' https://rusit-po.ru/xim/mail/excursion/', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			})
				.then((response) => {
					if (response.ok) {
						setIsMessageSuccess(true)
						setFormData({ name: '', phone_number: '' })
					} else {
						setIsMessageFail(true)
					}
				})
				.catch((error) => {
					console.error('An error occurred while submitting form data:', error)
				})
		} else {
			console.log('Please check the checkbox to submit the form.')
		}
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [])
	const closeModals = () => {
		setIsMessageFail(false)
		setIsMessageSuccess(false)
	}

	return (
		<>
			<section className="VideoAboutPage">
				{isMessageSuccess && (
					<ModalMessage
						modalText={['Спасибо!', 'Информация передана успешно. Ожидайте ответа.']}
						closeModals={closeModals}
					/>
				)}
				{isMessageFail && (
					<ModalMessage
						modalText={['Что-то пошло не так.', 'Проверьте подключение к интернету или попробуйте позже']}
						closeModals={closeModals}
					/>
				)}
				<div className="content">
					<h2 className="sectionTitle">О компании</h2>
					<div className="vide_bloc_about">
						<video width="100%" height="100%" controls poster={video_poster}>
							<source src={video} type="video/mp4" />
							Ваш браузер не поддерживает видео.
						</video>
					</div>
				</div>
			</section>
			<section className="ximvcifrax">
				<div className="content">
					<h2>XIM в цифрах</h2>
					<div className="advantages">
						<div className="advantages_item">
							<img src={Prem1} alt="Премущество" />
							<span>производственная мощность</span>
						</div>
						<div className="advantages_item">
							<img src={Prem2} alt="Премущество" />
							<span>производственных площадей</span>
						</div>
						<div className="advantages_item">
							<img src={Prem3} alt="Премущество" />
							<span>позиций в линейке продукции</span>
						</div>
					</div>
				</div>
			</section>
			<section className="about_Us">
				<div className="content">
					<div className="about_Us_it">
						<b>XIM</b>
						<p>
							является сертифицированным производителем смазочных материалов на территории России. Компания
							специализируется на разработке и производстве высококачественных масел и для автомобилей, промышленного
							оборудования и сельскохозяйственной техники. 
						</p>
					</div>
					<div className="about_Us_float">
						<img src={License} alt="Лицензия" />
						<div className="about_Us_it">
							<b>Наша миссия </b>
							<p>
								снабжать наших клиентов высококачественными смазочными материалами, которые могут существенно увеличить
								срок службы оборудования, его производительность и снизить затраты на обслуживание. 
							</p>
						</div>
						<div className="about_Us_it">
							<b>Мы используем</b>
							<p>
								только высококачественные ингредиенты, а также самые современные технологии выпуска и контроля качества,
								чтобы обеспечить полную защиту оборудования наших клиентов от износа и коррозии, снизить потерю энергии
								и предотвратить поломки и неисправности.
							</p>
						</div>
						<div className="about_Us_it">
							<b>Наша компания </b>
							<p>
								предлагает широкий ассортимент смазочных материалов, которые отвечают самым высоким стандартам качества.
								Мы производим масла и автохимию для всех типов двигателей и механизмов, включая моторные,
								трансмиссионные, гидравлические, редукторные и другие.
							</p>
						</div>
					</div>
					<div className="about_Us_it">
						<b>Мы готовы</b>
						<p>
							предоставить нашим клиентам наилучший сервис и консультацию в выборе и использовании наших продуктов, а
							также гарантировать качество продаваемой продукции. 
						</p>
					</div>
					<div className="about_Us_it">
						<b>Мы стремимся </b>
						<p>
							к тому, чтобы каждый клиент получал наилучший результат от использования нашей продукции. Опытная и
							профессиональная команда специалистов готовы помочь вам в любой ситуации. Вы можете быть уверены, что с
							нами вы получите продукцию высочайшего качества и лучший сервис.
						</p>
					</div>
				</div>
			</section>
			<section className="why_us">
				<div className="content">
					<div className="why_us_content_heading">
						<h2>Почему мы </h2>
						<span>Преимущества работы с нами</span>
					</div>
					<div className="why_us_content">
						<div className="why_us_content_left">
							<img src={whu1} alt="" />
							<img src={whu2} alt="" />
							<img src={whu3} alt="" />
						</div>
						<div className="why_us_content_right">
							<h2>Почему мы </h2>
							<span>Преимущества работы с нами</span>
							<div className="whu_is_items">
								<div className="whu_is_item">
									<div className="whu_is_item_number">1</div>
									<p>Современная лаборатория</p>
								</div>
								<div className="whu_is_item">
									<div className="whu_is_item_number">2</div>
									<p>Контроль качества сырья и готовой продукции</p>
								</div>
								<div className="whu_is_item">
									<div className="whu_is_item_number">3</div>
									<p>Инновационный метод смешивания</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="form_questions">
				<div className="content">
					<h2 className="form_Title">Записaться на экскурсию</h2>
					<p className="form_Sub_Title">Мы свяжемся с Вами в самое ближайшее время</p>
					<form onSubmit={handleSubmit}>
						<div className="form_inputs">
							<input
								type="text"
								placeholder="Как к Вам обращаться?"
								name="name"
								onChange={handleInputChange}
								required
							/>
							<PhoneInput
								onChange={handleInputChange}
								name="phone_number"
								required
							/>
						</div>
						<div className="politica">
							<label>
								<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
									<div id="checbox_form_after"></div>
								</div>
								<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
								<span className="checbox_form__text">
									Ознакомлен(а) с{' '}
									<Link className="checbox_form__link" to="/politic">
										пользовательским соглашением
									</Link>
								</span>
							</label>
						</div>
						<button type="submit">Отправить</button>
					</form>
				</div>
			</section>
			<section className="laboratory">
				<div className="content">
					<h2 className="sectionTitle">Лаборатория</h2>
					<div className="laborator_items">
						<div className="laborator_item_right">
							<div className="laborator_item_text">Сертифицированные специалисты с большим опытом работы</div>
							<img src={lab1} alt="Слайдер" />
						</div>
						<div className="laborator_item_left">
							<img src={lab2} alt="Слайдер" />
							<div className="laborator_item_text">Новое современное оборудование</div>
						</div>
						<div className="laborator_item_right">
							<div className="laborator_item_text">Создание сложных продуктов в рамках импортозамещения </div>
							<img src={lab3} alt="Слайдер" />
						</div>
						<div className="slid_next"></div>
						<div className="slid_prev"></div>
					</div>
				</div>
				<div className="lab_vide">
					<div className="content">
						<div className="labVide">
							<video width="100%" height="100%" controls poster={video_poster}>
								<source src={video__lab} type="video/mp4" />
								Ваш браузер не поддерживает видео.
							</video>
						</div>
					</div>
				</div>
			</section>
			<section className="form_questions">
				<div className="content">
					<h2 className="form_Title">Записaться на экскурсию</h2>
					<p className="form_Sub_Title">Мы свяжемся с Вами в самое ближайшее время</p>
					<form onSubmit={handleSubmit}>
						<div className="form_inputs">
							<input
								required
								type="text"
								placeholder="Как к Вам обращаться?"
								name="name"
								onChange={handleInputChange}
							/>
							<PhoneInput
								onChange={handleInputChange}
								name="phone_number"
								required
							/>
						</div>
						<div className="politica">
							<label>
								<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
									<div id="checbox_form_after"></div>
								</div>
								<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
								<span className="checbox_form__text">
									Ознакомлен(а) с{' '}
									<Link className="checbox_form__link" to="/politic">
										пользовательским соглашением
									</Link>
								</span>
							</label>
						</div>
						<button type="submit">Отправить</button>
					</form>
				</div>
			</section>

			<section className="slider_bloc">
				<div id="swiper-back" className="slide_prew">
					<img src={prev} alt="" />
				</div>
				<Swiper
					modules={[Navigation, Pagination, Scrollbar, A11y]}
					spaceBetween={20}
					slidesPerGroupSkip={1}
					slidesPerView={isWideScreen ? 3 : 2}
					navigation={{ nextEl: '#swiper-forward', prevEl: '#swiper-back' }}
					loop
				>
					<SwiperSlide className="slider_item">
						<img src={slid1} alt="" />
					</SwiperSlide>
					<SwiperSlide className="slider_item">
						<img src={slid2} alt="" />
					</SwiperSlide>
					<SwiperSlide className="slider_item">
						<img src={slid3} alt="" />
					</SwiperSlide>
					<SwiperSlide className="slider_item">
						<img src={slid4} alt="" />
					</SwiperSlide>
					<SwiperSlide className="slider_item">
						<img src={slid5} alt="" />
					</SwiperSlide>
				</Swiper>
				<div id="swiper-forward" className="slide_next">
					<img src={next} alt="" />
				</div>
			</section>
			<section className="brend">
				<div className="content">
					<div className="brend_content">
						<div className="brend_content_images">
							<img src={brend} alt="" />
						</div>
						<div className="brend_text">
							<img src={text} alt="" />
						</div>
					</div>
				</div>
			</section>
			<section className="you_getting">
				<div className="content">
					<h2>Почему мы </h2>
					<span className="you_getting_subtitle">Преимущества работы с нами </span>
					<div className="you_getting_items">
						<div className="you_getting_item">
							<div className="you_getting_item_number">1</div>
							<span className="you_getting_item_text">Современная лаборатория, создадим даже сложные продукты.</span>
						</div>
						<div className="you_getting_item">
							<div className="you_getting_item_number">2</div>
							<span className="you_getting_item_text">
								Контроль качества. Контроль качества сырья и готовой продукции.
							</span>
						</div>
						<div className="you_getting_item">
							<div className="you_getting_item_number">3</div>
							<span className="you_getting_item_text">Создадим готовый продукт в срок от 30 дней.</span>
						</div>
					</div>
				</div>
			</section>
			<section className="you_getting_2">
				<div className="content">
					<div className="content2_text">
						<img src={get_word} alt="" />
						{/* <h2>Вы получаете</h2> */}
						<p>Независимость от крупных брендов и импортных поставщиков</p>
						<p>Эксклюзивный продукт, стабильно высокого качества</p>
						<p>Низкую себестоимсоть продукта</p>
					</div>
				</div>
			</section>
			<section className="form_questions">
				<div className="content">
					<h2 className="form_Title">Записaться на экскурсию</h2>
					<p className="form_Sub_Title">Мы свяжемся с Вами в самое ближайшее время</p>
					<form onSubmit={handleSubmit}>
						<div className="form_inputs">
							<input
								required
								type="text"
								placeholder="Как к Вам обращаться?"
								name="name"
								onChange={handleInputChange}
							/>
							<PhoneInput
								onChange={handleInputChange}
								name="phone_number"
								required
							/>
						</div>
						<button type="submit">Отправить</button>
					</form>
					<div className="politica">
						<label>
							<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
								<div id="checbox_form_after"></div>
							</div>
							<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
							<span className="checbox_form__text">
								Ознакомлен(а) с{' '}
								<Link className="checbox_form__link" to="/politic">
									пользовательским соглашением
								</Link>
							</span>
						</label>
					</div>
				</div>
			</section>
		</>
	)
}

export { About }
