import { Link } from 'react-router-dom'
import { productsByCategories } from '@src/helpers/productsByCategories'
import getCategoryByPath from '@src/utility/getCategoryByPath'
import getProductInfo from '@src/utility/getProductInfo'

const ProductLink = ({category, productID, isActive}) => {
	const productInfo = getProductInfo(productID)

	return (
		<li className={isActive ? 'product-list-active' : ''}>
			<Link to={`${category}/${productID}`}>
				{
					(productInfo?.brand || productInfo?.name) ?
					<>
						{productInfo?.brand} <span>{productInfo?.name}</span>
					</>
					:
					<>
						{productID}
					</>
				}
			</Link>
		</li>
	)
}

const CatalogSubNav = ({category, product}) => {

	const { categoryID } = getCategoryByPath(category)
	const products = productsByCategories[categoryID]

	if (!products || !Array.isArray(products) || !products?.length) {
		return (
			<>В данной категории продуктов нет</>
		)

	} else {
		return (
			<ul id="catalog_nav_step2">
				{Object.keys(products).map((id) => (
					<ProductLink
						key={id}
						productID={products[id][0]}
						isActive={product === products[id][0]}
						{...{category}}
					/>
				))}
			</ul>
		)
	}
}

export default CatalogSubNav