import { useNavigate } from 'react-router-dom'
import getProductInfo from '@src/utility/getProductInfo'
import ProductTabber from '@src/components/productTabber/ProductTabber'
import ProductDetail from '@src/components/productDetail/ProductDetail'
import ProductActions from '@src/components/productActions/ProductActions'

const CatalogProduct = ({product}) => {
	const productInfo = getProductInfo(product)

	const navigate = useNavigate()

	const goBack = () => {
		navigate(-1)
	}

	return (
		<div className="catalog_product">
			<button onClick={goBack} className="btn btn_back">
				Назад
			</button>
			<ProductDetail data={productInfo} />
			<ProductTabber {...{product}} />
			<ProductActions {...{product}} />
		</div>
	)
}

export default CatalogProduct