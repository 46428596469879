export const XIM_MARKA_A = 'XIM-MARKA-A'
export const XIM_2T_MOTOR = 'XIM-2T-MOTOR'
export const XIM_TOSOL_40_65 = 'XIM-TOSOL-40-65'
export const XIM_4T_MOTOR = 'XIM-4T-MOTOR'
export const XIM_4T_SPORT_MOTOR = 'XIM-4T-SPORT-MOTOR'
export const XIM_ATF_DEXRON_II = 'XIM-ATF-DEXRON-II'
export const XIM_ATF_DEXRON_III = 'XIM-ATF-DEXRON-III'
export const XIM_ATF_DEXRON_VI_PAO = 'XIM-ATF-DEXRON-VI-PAO'
export const XIM_ATF_PAO_MULTI = 'XIM-ATF-PAO-MULTI'
export const XIM_ATF_SYNTHETIC_MULTI = 'XIM-ATF-SYNTHETIC-MULTI'
export const XIM_AU = 'XIM-AU'
export const XIM_VMGZ_45 = 'XIM-VMGZ-45'
export const XIM_VMGZ_55 = 'XIM-VMGZ-55'
export const XIM_VMGZ_60_PAO = 'XIM-VMGZ-60-PAO'
export const XIM_CIRCULATION = 'XIM-CIRCULATION'
export const XIM_CVT_FLUID = 'XIM-CVT-FLUID'
export const XIM_FORMA = 'XIM-FORMA'
export const XIM_TRANSMISSION_HYD_UTTO_10W30_API_GL_4 = 'XIM-TRANSMISSION-HYD-UTTO-10W30-API-GL-4'
export const XIM_IGP_18_IGP_30_IGP_38_IGP_49 = 'XIM-IGP-18-IGP-30-IGP-38-IGP-49'
export const XIM_IGP_72_IGP_91_IGP_114 = 'XIM-IGP-72-IGP-91-IGP-114'
export const XIM_I_12A = 'XIM-I-12A'
export const XIM_I_20A_I_30A_I_40A_I_50A = 'XIM-I-20A-I-30A-I-40A-I-50A'
export const XIM_I_8A = 'XIM-I-8A'
export const XIM_MARKA_P = 'XIM-MARKA-P'
export const XIM_MGE_46B = 'XIM-MGE-46B'
export const XIM_MGT = 'XIM-MGT'
export const XIM_MG_8A = 'XIM-MG-8A'
export const XIM_M_8B = 'XIM-M-8B'
export const XIM_M_8BD = 'XIM-M-8BD'
export const XIM_M_8G2K_M_10G2K = 'XIM-M-8G2K-M-10G2K'
export const XIM_PS_CHF = 'XIM-PS-CHF'
export const XIM_REDUCTOR_OIL_CLP = 'XIM-REDUCTOR-OIL-CLP'
export const XIM_REDUCTOR_OIL_CLP_SYNTHETIC = 'XIM-REDUCTOR-OIL-CLP-SYNTHETIC'
// export const XIM_REDUCTOR_OIL_SVG = 'XIM-REDUCTOR-OIL-SVG'
export const XIM_REDUCTOR_OIL_SVG_PAO = 'XIM-REDUCTOR-OIL-SVG-PAO'
export const XIM_REDUCTOR_OIL_CLPF = 'XIM-REDUCTOR-OIL-CLPF'
export const XIM_SLIDE_WAY_SYNT = 'XIM-SLIDE-WAY-SYNT'
export const XIM_SLIDE_WAY = 'XIM-SLIDE-WAY'
export const XIM_TAD17I = 'XIM-TAD17I'
export const XIM_XCLEAN_10 = 'XIM-XCLEAN-10'
export const XIM_XCLEAN_20 = 'XIM-XCLEAN-20'
export const XIM_XCLEAN_30 = 'XIM-XCLEAN-30'
export const XIM_XCLEAN_MOSQUIT = 'XIM-XCLEAN-MOSQUIT'
export const XIM_XCOOL_G11_40_65 = 'XIM-XCOOL-G11-40-65'
export const XIM_XCOOL_G12_40_65 = 'XIM-XCOOL-G12-40-65'
export const XIM_XTRUCK_EURO2 = 'XIM-XTRUCK-EURO2'
export const XIM_XTRUCK_EURO3 = 'XIM-XTRUCK-EURO3'
export const XIM_XTRUCK_PRO = 'XIM-XTRUCK-PRO'
export const XIM_XTRUCK_SYNTHETIC = 'XIM-XTRUCK-SYNTHETIC'
export const XIM_XTRUCK_SYNTHETIC_DPF = 'XIM-XTRUCK-SYNTHETIC-DPF'
export const XIM_XTRUCK_SYNTHETIC_PAO = 'XIM-XTRUCK-SYNTHETIC-PAO'
export const XIM_XTRUCK_SYNTHETIC_PRO = 'XIM-XTRUCK-SYNTHETIC-PRO'
export const XIM_SAE = 'XIM-SAE'
export const XIM_TRANSMISSION_75W_85_API_GL_4 = 'XIM-TRANSMISSION-75W-85-API-GL-4'
export const XIM_TRANSMISSION_75W_90_API_GL_4 = 'XIM-TRANSMISSION-75W-90-API-GL-4'
export const XIM_TRANSMISSION_75W_90_API_GL_4_5 = 'XIM-TRANSMISSION-75W-90-API-GL-4-5'
export const XIM_TRANSMISSION_80W90_85W_90_API_GL_4 = 'XIM-TRANSMISSION-80W90-85W90-API-GL-4'
export const XIM_TRANSMISSION_80W_140_85W140_API_GL_5 = 'XIM-TRANSMISSION-80W-140-85W140-API-GL-5'
export const XIM_TRANSMISSION_80W_90_85W_90_API_GL_5 = 'XIM-TRANSMISSION-80W-90-85W90-API-GL-5'
export const XIM_TRANSMISSION_80W_90_85W_90_API_GL_4_5 = 'XIM-TRANSMISSION-80W-90-85W-90-API-GL-4-5'
export const XIM_TRANSMISSION_HYD = 'XIM-TRANSMISSION-HYD'
export const XIM_TRANSMISSION_PAO_75W_90_API_GL_4_5 = 'XIM-TRANSMISSION-PAO-75W-90-API-GL-4-5'
export const XIM_TRANSMISSION_PAO_75W_90_API_GL_5 = 'XIM-TRANSMISSION-PAO-75W-90-API-GL-5'
export const XIM_TRANSMISSION_PAO_LS_75W_140_API_GL_5 = 'XIM-TRANSMISSION-PAO-LS-75W-140-API-GL-5'
export const XIM_TRANSMISSION_SYNT_75W_90_85W_90_LS_API_GL_5 = 'XIM-TRANSMISSION-SYNT-75W-90-85W-90-LS-API-GL-5'
export const XIM_TRANSMISSION_SYNT_75W_90_API_GL_4 = 'XIM-TRANSMISSION-SYNT-75W-90-API-GL-4'
export const XIM_TRANSMISSION_SYNT_75W_90_API_GL_4_5 = 'XIM-TRANSMISSION-SYNT-75W-90-API-GL-4-5'
export const XIM_TRANSMISSION_SYNT_75W_90_API_GL_5 = 'XIM-TRANSMISSION-SYNT-75W-90-API-GL-5'

export const XIM_M_8DM_M_10DM = 'XIM-M-8DM-M-10DM'
export const XIM_М_8В = 'XIM-М-8В'

export const XIM_XGAS_LD5000_PAO = 'XIM-XGAS-LD5000-PAO'
export const XIM_XGAS_LD5000_SYNT = 'XIM-XGAS-LD5000-SYNT'


export const XIM_HYDRAULIC_HLP = 'XIM-HYDRAULIC-HLP'
export const XIM_HYDRAULIC_HVLP = 'XIM-HYDRAULIC-HVLP'
export const XIM_HYDRAULIC_HVLP_ARCTIC_PAO = 'XIM-HYDRAULIC-HVLP-ARCTIC-PAO'
// export const XIM_HYDRAULIC_PRO = 'XIM-HYDRAULIC-PRO'
// export const XIM_HYDRAULIC_PRO_PAO = 'XIM-HYDRAULIC-PRO-PAO'

export const XIM_HYDRAULIC_SE_HLP = 'XIM-HYDRAULIC-SE-HLP'
export const XIM_HYDRAULIC_ZF_HLP = 'XIM-HYDRAULIC-ZF-HLP'
export const XIM_HYDRAULIC_SE_HVLP = 'XIM-HYDRAULIC-SE-HVLP'
export const XIM_HYDRAULIC_ZF_HVLP = 'XIM-HYDRAULIC-ZF-HVLP'
export const XIM_HYDRAULIC_HVLP_PRO_PAO = 'XIM-HYDRAULIC-HVLP-PRO-PAO'
export const XIM_HYDRAULIC_ZF_HVLP_PRO_PAO = 'XIM-HYDRAULIC-ZF-HVLP-PRO-PAO'


export const XIM_COMOR_VDL = 'XIM-COMOR-VDL'
export const XIM_COMOR_VDL_ARCTIC_PAO = 'XIM-COMOR-VDL-ARCTIC-PAO'
export const XIM_COMOR_VDL_TERMO_PAO = 'XIM-COMOR-VDL-TERMO-PAO'