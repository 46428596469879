import './modal.css'
import kanistra_img from '../../img/modal_kanistra.png'
import close_modal from '../../img/close_modal_img.png'
const ModalMessage = ({ closeModals, modalText }) => {
	return (
		<div className="modal-container">
			<div className="modal-overlay">
				<div className="modal">
					<div onClick={closeModals} className="modal-close">
						<img src={close_modal} alt="" />
					</div>
					<div className="modal-content">
						<img src={kanistra_img} alt="" />
						<div className="modal-form">
							<h2 className="modal-title">{modalText[0]}</h2>
							<p className="modal-text">{modalText[1]}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalMessage
