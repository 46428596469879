import { useNavigate } from 'react-router-dom'
import './notFoundPage.css'

const NotFoundPage = () => {
	const navigate = useNavigate()

	const goBack = () => {
		navigate('/')
	}
	return (
		<section>
			<div className="content">
				<div className="notFoundPage">
					<p className='notFoundPage_text'>
						Страница не найдена. Перейдите на главную страницу.
					</p>
					<button onClick={goBack} className="btn notFoundPage_btn">
						На главную
					</button>
				</div>
			</div>
		</section>
	)
}

export { NotFoundPage }
