import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { About } from '@pages/about/Aboutpage'
import { Buy } from '@pages/buy/buy'
// import { Catalog } from '@pages/catalog/Catalog'
import { Contact } from '@pages/Contact/contact'
import { Cooperation } from '@pages/cooperation/Cooperation'
import { Homepage } from '@pages/home/Homepage'
import { Layout } from './components/Layout'
import { NotFoundPage } from '@pages/not-found-page/NotFoundPage'
import { TermsOfUse } from '@pages/terms-of-use/TermsOfUse'
// import CatalogRoutes from './CatalogRouter'
import { path } from './config'
import Catalog from '@pages/catalog/Catalog'

const {
	DEFAULT,
	ANY,
	CATALOG,
	BUY,
	CONTACT,
	ABOUT,
	COOPERATION,
	POLITIC,
} = path

const GlobalRouter = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path={DEFAULT} element={<Layout />}>
					<Route index element={<Homepage />} />
					<Route path={BUY} element={<Buy />} />
					<Route path={CONTACT} element={<Contact />} />
					<Route path={ABOUT} element={<About />} />
					<Route path={COOPERATION} element={<Cooperation />} />
					<Route path={POLITIC} element={<TermsOfUse />} />
					<Route path={ANY} element={<NotFoundPage />} />
					<Route path={CATALOG} element={<Catalog />} />
					<Route path={CATALOG}>
						<Route index element={<Catalog />} />
						<Route path={ANY} element={<Catalog />} />
					</Route>
					{/* <Route path={CATALOG}>
						<Route index element={<Catalog />} />
						<Route path={ANY} element={<CatalogRoutes />} />
					</Route> */}
					<Route path={ANY} element={<NotFoundPage />} />
				</Route>
			</Routes>
		</BrowserRouter>
	)
}

export default GlobalRouter
