export const PASSENGER_CARS      = 'PASSENGER_CARS'
export const COMMERCIAL_VEHICLES = 'COMMERCIAL_VEHICLES'
export const GAS_ENGINES_OILS    = 'GAS_ENGINES_OILS'
export const TRANSMISSION_OILS   = 'TRANSMISSION_OILS'
export const GUR                 = 'GUR'
export const HYDRAULIC           = 'HYDRAULIC'
export const REDUCTOR            = 'REDUCTOR'
export const INDUSTRIAL          = 'INDUSTRIAL'
export const SMALL_EQUIPMENT     = 'SMALL_EQUIPMENT'
export const LIQUIDS             = 'LIQUIDS'
