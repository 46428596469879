import './catalog.css'

const CatalogWrapper = ({children}) => {
	return (
		<div className="catalog">
			<div className="content">
				{children}
			</div>
		</div>
	)
}

export default CatalogWrapper
