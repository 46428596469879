import './home.css'
import { MainPromo } from './../../components/mainPromo/mainPromo'
import { AboutMainpage } from './../../components/aboutHomepage/about'
import { Products } from './../../components/products/products'
import { CatalogHomepage } from './../../components/catalogHomepage/catalog'
import { FormHome } from './../../components/formHomepage/formHomepage'
const Homepage = () => {
	return (
		<div>
			<MainPromo />
			<AboutMainpage />
			<Products />
			<CatalogHomepage />
			<FormHome />
		</div>
	)
}

export { Homepage }
