const ProductDetail = ({data}) => {
	const values = data?.values.map(val => (`${val} л`))
	const fotoBochka = '/barrel.png'

	return (
		<>
			<div className="catalog_product_top">
				<div className="cat_img_foto">
					<img src={fotoBochka} alt={data?.brand || 'XIM'} />
				</div>
				<div className="catalog_product_top_right">
					<h2>
						{data?.brand} <span>{data?.name}</span>
					</h2>
					<span className="Xim_name">
						{data?.vars && data?.vars.join(' / ')}
						{data?.spec && <br />}
						{data?.spec}
					</span>
					{data?.desc &&
						<p className="XIM_info">
							{data?.desc}
						</p>
					}
					{data?.req &&
						<p className="trebovanie">
							Cоответствия требованиям: {data?.req}
						</p>
					}
				</div>
			</div>
			<div className="fas_and_seson">
				<div className="fasovka">
					<div className="fas_and_seson_name">ВИД ФАСОВКИ:</div>
					<span>{values && values.join(' / ')}</span>
				</div>
				<div className="seson">
					<div className="fas_and_seson_name">СЕЗОН:</div>
					<span
						dangerouslySetInnerHTML={{
							__html: data?.seasons ? data.seasons.replace(/\n/g, "<br/>") : 'Все сезоны',
						}}
					/>
				</div>
			</div>
		</>
	)
}

export default ProductDetail