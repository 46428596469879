import './products.css'
import bochka from './../../img/bochka.png'
import { Link } from 'react-router-dom'
const Products = () => {
	return (
		<>
			<section className="Products">
				<h2 className="sectionTitle">Наша продукция</h2>
				<div className="bochka_content">
					<img src={bochka} alt="Бочка масла" />
				</div>
				<Link to='/about'>
				<button className="btn read_more">Узнать больше</button>
				</Link>
			</section>
		</>
	)
}
export { Products }
