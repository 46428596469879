import { AuthProvider } from './hoc/AuthProvider'
import GlobalRouter from './GlobalRouter'

const App = () => {
	return (
		<AuthProvider>
			<GlobalRouter />
		</AuthProvider>
	)
}

export default App
