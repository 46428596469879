import './termsOfUse.css'
import { useNavigate } from 'react-router-dom'
const TermsOfUse = () => {
	const navigate = useNavigate()

	const goBack = () => {
		navigate(-1)
	}
	return (
		<section>
			<div className="content">
				<div className="termsOfUse">
					<p className="termsOfUse_text">
						Пользовательское соглашение Настоящим Я, действуя своей волей и в своем интересе, при размещении (вводе)
						своих персональных данных на Интернет сайте xim.ru общества с ограниченной ответственностью “ИКСИМ” (ИНН
						6949115461, ОГРН 1216900014198, далее - Оператор) подтверждаю свое согласие на обработку указанных мной
						персональных данных Оператором в целях предложения мне услуг, новых услуг, предлагаемых Оператором, в целях
						проведения опросов, анкетирования, рекламных и маркетинговых исследований в отношении услуг, предоставляемых
						Оператором, в том числе путем осуществления прямых контактов со мною посредством средств связи, указанных
						мной на настоящем сайте.
					</p>
					<p className="termsOfUse_text">
						Настоящее право (согласие) предоставляется на осуществление любых действий в отношении моих персональных
						данных, которые необходимы и желаемы для достижения вышеуказанных целей, включая, без ограничения, сбор,
						систематизацию, накопление, хранение, уточнение (обновление, изменение), использование, передачу,
						обезличивание, блокирование и уничтожение персональных данных, под которыми понимаются все данные, указанные
						мной на настоящем сайте. Настоящим подтверждаю, что уведомлен о том, что обработка персональных данных
						осуществляется Оператором любым способом, в том числе как с использованием средств автоматизации (включая
						программное обеспечение), так и без использования средств автоматизации (с использованием различных
						материальных носителей, включая бумажные носители).
					</p>
					<button onClick={goBack} className="btn termsOfUse_btn">
						Назад
					</button>
				</div>
			</div>
		</section>
	)
}

export { TermsOfUse }
