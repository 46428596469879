import { Link } from 'react-router-dom'

const ProductActions = ({product}) => {
	return (
		<div className="btn_cont_catalog">
			<Link
				to={`/data/products/${product}/${product}.pdf`} target="_blank"
			>
				Скачать документ
			</Link>
			<button /* onClick={openModal} */>Заказать</button>
		</div>
	)
}

export default ProductActions