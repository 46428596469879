import useTitle from '@src/hooks/useTitle'
import useCatalog from '@src/hooks/useCatalog'
import CatalogNav from './CatalogNav'
import CatalogProduct from './CatalogProduct'
import CatalogSubNav from './CatalogSubNav'
import CatalogWrapper from './CatalogWrapper'

const Catalog = () => {
	const title = useTitle('Каталог')
	const { category, product } = useCatalog()

	return (
		<CatalogWrapper>
			<h1>{title}</h1>
			<div className={`catalog_content ${product ? 'catalog_product-selected' : ''}`}>
				<CatalogNav {...{ product, category }} />
				<div className="catalog_product__big">
					{
						product && <CatalogProduct {...{product}} />
					}
					{
						(!product && category) && <CatalogSubNav {...{category}} />
					}
				</div>
			</div>
		</CatalogWrapper>
	)
}

export default Catalog
