import { useState, useEffect } from 'react'

const useProductData = (product, fileName) => {
	const [data, setData] = useState(null)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		const fetchData = async () => {
			const path = `/data/products/${product}/${fileName}.json`
			try {
				const response = await fetch(path)
				if (!response.ok) {
					throw new Error('Network response was not ok')
				}
				const result = await response.json()
				setData(result)
			} catch (error) {
				setError(error)
				setData(null)
			}
			setLoading(false)
		}

		fetchData()
	}, [product])

	return { data, error, loading }
}

export default useProductData