import { DEFAULT, ANY } from "./helpers/constants"

export const path = {
	[DEFAULT]: '/',
	[ANY]: '*',
	CATALOG: 'catalog',
	BUY: 'buy',
	CONTACT: 'contact',
	ABOUT: 'about',
	COOPERATION: 'cooperation',
	POLITIC: 'politic',
}