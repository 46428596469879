import './mainPromo.css'
import mathor from './../../img/mathor.png'
import mathorM from './../../img/mator.png'
import logo from '../../img/XIM-01.png'
const MainPromo = () => {
	return (
		<>
			<section className="HomePromo">
				<div className="content">
					<div className="HomePromo_content">
						<div className="HomePromo_left">
							<img src={logo} alt="" />
							<div className="hr_promo"></div>
							<h3>Производство</h3>
							<span>смазочных материалов</span>
						</div>
						<div className="img_mathor">
							<img src={mathor} alt="" />
							<img src={mathorM} className="mathor" alt="" />
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
export { MainPromo }
