import { useMatch } from 'react-router-dom'

const useCatalog = () => {
	const selectedCategory = useMatch('/catalog/:category')
	const selectedProduct = useMatch('/catalog/:category/:product')

	let category = ''
	let product = ''

	if (selectedProduct) {
		category = selectedProduct?.params?.category || ''
		product = selectedProduct?.params?.product?.toUpperCase() || ''
	} else if (selectedCategory) {
		category = selectedCategory?.params?.category || ''
	}

	return { category, product }
}

export default useCatalog
