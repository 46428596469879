import './about.css'
import { useNavigate, Link } from 'react-router-dom'

import video from '../../video/video.mp4'
import video_poster from '../../img/video_poster.png'

const AboutMainpage = () => {
	const navigate = useNavigate()

	return (
		<>
			<section className="About_Homepage">
				<h2 className="sectionTitle">О компании</h2>
				<div className="content">
					<div className="about_content_right">
						<video width="100%" height="100%" controls poster={video_poster}>
							<source src={video} type="video/mp4" />
							Ваш браузер не поддерживает видео.
						</video>
					</div>
					<div className="about_content_left">
						<p>
							В мире, где каждая деталь, каждый механизм требует особого внимания и ухода, XIM находится на передовой
							инновации в производстве масел и жидкостей высшего класса. С момента основания компания XIM показывает
							высокий уровень качества при производстве моторных, гидравлических, трансмиссионных и сложных масел, а
							также жидкостей премиум-класса. Мы объединяем передовые технологии, тщательное развитие и постоянное
							стремление к совершенству, позволяет производить продукцию, отвечающую самым высоким требованиям
							современного рынка.
						</p>
					</div>
					<Link to="/about">
						<button className="btn read_more read_more_about">Узнать больше</button>
					</Link>
				</div>
			</section>
		</>
	)
}
export { AboutMainpage }
