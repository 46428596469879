import { categories } from '@src/helpers/categoriesInfo'

const getCategoryByPath = (targetPath) => {
	const entry = Object.entries(categories).find(
		([, value]) => value.path === targetPath
	)
	const id = entry ? entry[0] : null
	return {
		categoryID: id,
		categoryInfo: id ? categories[id] : null
	}
}

export default getCategoryByPath