import { useEffect } from 'react'
import { siteName } from '@src/helpers/text'

const useTitle = (title) => {
	useEffect(() => {
		document.title = `${title} – ${siteName}`
	}, [title])

	return title
}

export default useTitle
