import './contact.css'
import video_moscow from '../../video/moscowMP4.MP4'
import video_saintp from '../../video/piter.MP4'
import { useState } from 'react'
import contactMap from './../../img/contactMap.png'
import { Link } from 'react-router-dom'
import ModalMessage from '../../components/modalMessage/ModalMessage'
import PhoneInput from '@src/components/phoneInput/PhoneInput'

const Contact = () => {
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
	const [isMessageFail, setIsMessageFail] = useState(false)
	const [isMessageSuccess, setIsMessageSuccess] = useState(false)
	const [formData, setFormData] = useState({
		name: '',
		phone_number: '',
	})

	const handleCheckboxChange = (e) => {
		setIsCheckboxChecked(e.target.checked)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (isCheckboxChecked) {
			const url = 'https://rusit-po.ru/xim/mail/write-us/'

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				mode: 'cors',
				body: JSON.stringify(formData),
			})
				.then((response) => {
					if (response.ok) {
						setIsMessageSuccess(true)
						setFormData({ name: '', phone_number: '' })
					} else {
						setIsMessageFail(true)
					}
				})
				.catch((error) => {
					console.error('Error:', error)
				})
		} else {
			console.log('Please check the checkbox to submit the form.')
		}
	}

	const closeModals = () => {
		setIsMessageFail(false)
		setIsMessageSuccess(false)
	}
	return (
		<>
			<section className="contact">
				{isMessageSuccess && (
					<ModalMessage
						modalText={['Спасибо!', 'Информация передана успешно. Ожидайте ответа.']}
						closeModals={closeModals}
					/>
				)}
				{isMessageFail && (
					<ModalMessage
						modalText={['Что-то пошло не так.', 'Проверьте подключение к интернету или попробуйте позже']}
						closeModals={closeModals}
					/>
				)}
				<div className="content">
					<h1>Контакты</h1>
					<div className="contact_adress">
						<div className="contact_adress_text">
							<span className="contact_adress_text_title">Телефон</span>
							<a href="tel:+7 (495) 123-35-77">+7 (495) 123-35-77</a>
							<span className="contact_adress_text_title">График работы</span>
							<p>
								Пн-чт 9:00-18:00
								<br></br>Пт 9:00-17:00
							</p>
							<span className="contact_adress_text_title">Почта</span>
							<a href="mailto:zakaz@okten.ru">zakaz@okten.ru</a>
							<span className="contact_adress_text_title">Адрес</span>
							<p>Тверская область, Конаковский район, д. Старое Мелково, ул. Центральная 6</p>
						</div>
						<div className="contactMap">
							<img src={contactMap} alt="Map" />
						</div>
					</div>
					<div className="view_map">
						<a href="https://yandex.ru/maps/?ll=36.435058%2C56.656146&mode=routes&rtext=~56.655748%2C36.434308&rtt=mt&ruri=~&z=18.21">
							<button>Построить маршрут</button>
						</a>
					</div>
					<div className="route">
						<h2 className="route_title">Как к нам проехать</h2>
						<div className="route_items">
							<div className="route_item">
								<video controls>
									<source src={video_moscow} type="video/mp4" />
									Ваш браузер не поддерживает видео.
								</video>
								<span>Из Москвы</span>
							</div>
							<div className="route_item">
								<video controls>
									<source src={video_saintp} type="video/mp4" />
									Ваш браузер не поддерживает видео.
								</video>
								<span>Из Санкт - Петербурга</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className="form_home">
				<div className="content">
					<h2 className="form_title">Напиши нам</h2>
					<p className="formSubTitle">Мы свяжемся с Вами в самое ближайшее время</p>
					<form id="form_home" className="form_homePage" onSubmit={handleSubmit}>
						<input required type="text" name="name" placeholder="Как к Вам обращаться?" onChange={handleInputChange} />
						<PhoneInput
							onChange={handleInputChange}
							name="phone_number"
							required
						/>
						<input type="submit" value="Отправить" />
					</form>
					<div className="politica">
						<label>
							<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
								<div id="checbox_form_after"></div>
							</div>
							<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
							<span className="checbox_form__text">
								Ознакомлен(а) с{' '}
								<Link className="checbox_form__link" to="/politic">
									пользовательским соглашением
								</Link>
							</span>
						</label>
					</div>
				</div>
			</section>
		</>
	)
}

export { Contact }
