import useAdvantages from '@src/hooks/product/useAdvantages'
import useApplication from '@src/hooks/product/useApplication'
import useCharacterTable from '@src/hooks/product/useCharacterTable'
import useDesc from '@src/hooks/product/useDesc'
import { useEffect, useState } from 'react'
import CharacterTable from '../characterTable/CharacterTable'

const TabButton = ({onClick, tabId, tabName, activeTab}) => {
	return (
		<li
			data-id={tabId}
			className={activeTab === tabId ? "active_more_info" : ''}
			{...{onClick}}
		>
			{tabName}
		</li>
	)
}

const ProductTabber = ({product}) => {
	const defaultTab = 'description'
	const [activeTab, setActiveTab] = useState(defaultTab)

	useEffect(() => {
		setActiveTab(defaultTab)
	}, [product])

	const onClickDescription = (event) => {
		const tabId = event?.target?.dataset?.id
		setActiveTab(tabId)
	}

	const {
		data: desc,
		loading: descLoading
	} = useDesc(product)
	const {
		data: productCharacters,
		loading: productCharactersLoading
	} = useCharacterTable(product)
	const {
		data: advantages,
		loading: advantagesLoading
	} = useAdvantages(product)
	const {
		data: application,
		loading: applicationLoading
	} = useApplication(product)

	return (
		<div className="more_info">
			<div className="nav_bloc">
				<ul id="more_info_nav">
					{(!descLoading && desc) ?
						<TabButton
							onClick={onClickDescription}
							tabId="description"
							tabName="Описание"
							{...{activeTab}}
						/>
						: ''
					}
					{(!productCharactersLoading && productCharacters) ?
						<TabButton
							onClick={onClickDescription}
							tabId="characteristics"
							tabName="Характеристики"
							{...{activeTab}}
						/>
						: ''
					}
					{(!advantagesLoading && advantages) ?
						<TabButton
							onClick={onClickDescription}
							tabId="advantages"
							tabName="Преимущества"
							{...{activeTab}}
						/>
						: ''
					}
					{(!applicationLoading && application) ?
						<li
							onClick={onClickDescription}
							data-id="application"
							className={activeTab === 'application' ? "active_more_info" : ''}
						>
							Применение
						</li>
						: ''
					}
				</ul>
			</div>
			<div>
				{activeTab == 'description' &&
					<div className="desc_cont">
						{desc && desc.map((item, index) =>
							<p key={index}>{item}</p>
						)}
					</div>
				}
				{activeTab == 'characteristics' &&
					<div className="character_cont">
						{productCharacters &&
							<CharacterTable data={productCharacters} />
						}
					</div>
				}
				{activeTab == 'advantages' &&
					<div className="advantages_cont">
						{advantages && advantages.map((item, index) =>
							<p key={index}>{item}</p>
						)}
					</div>
				}
				{activeTab == 'application' &&
					<div className="application_cont">
						{application && application.map((item, index) =>
							<p key={index}>{item}</p>
						)}
					</div>
				}
			</div>
		</div>
	)
}

export default ProductTabber