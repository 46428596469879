const CharacterTable = ({data}) => {
	return (
		<table className="character_table">
			<thead>
				<tr>
					{data[0].map((header, index) => (
						<th
						key={index}
						dangerouslySetInnerHTML={{
							__html: header.replace(/\n/g, "<br/>"),
						}}
						/>
					))}
				</tr>
			</thead>
			<tbody>
				{data.slice(1).map((row, rowIndex) => (
					<tr key={rowIndex}>
					{row.map((cell, cellIndex) => (
						<td
							key={cellIndex}
							dangerouslySetInnerHTML={{
								__html: cell.replace(/\n/g, "<br/>"),
							}}
						/>
					))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default CharacterTable