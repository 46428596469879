// import img1 from '@src/img/catalogHomepage/catalog_it1.png'
import img2 from '@src/img/catalogHomepage/catalog_it2.png'
import img3 from '@src/img/catalogHomepage/catalog_it3.png'
import img4 from '@src/img/catalogHomepage/catalog_it4.png'
import img5 from '@src/img/catalogHomepage/catalog_it5.png'
import img6 from '@src/img/catalogHomepage/catalog_it6.png'
// import img7 from '@src/img/catalogHomepage/catalog_it7.png'
import img8 from '@src/img/catalogHomepage/catalog_it8.png'

import {
	COMMERCIAL_VEHICLES,
	GAS_ENGINES_OILS,
	GUR,
	HYDRAULIC,
	INDUSTRIAL,
	LIQUIDS,
	// PASSENGER_CARS,
	// SMALL_EQUIPMENT,
	TRANSMISSION_OILS
} from './categories'

export const categories = {
	// [PASSENGER_CARS]: {
	// 	name: 'Масла для легковых автомобилей',
	// 	path: 'passengerCars',
	// 	icon: img1
	// },
	[COMMERCIAL_VEHICLES]: {
		name: 'Масла для коммерческой техники',
		path: 'commercialVehicles',
		icon: img2
	},
	[GAS_ENGINES_OILS]: {
		name: 'Масла для газовых двигателей',
		path: 'gasEnginesOils',
		icon: img2
	},
	[TRANSMISSION_OILS]: {
		name: 'Трансмиссионные масла',
		path: 'transmissionOils',
		icon: img3
	},
	[GUR]: {
		name: 'Масло ГУР',
		path: 'gur',
		icon: img6
	},
	[HYDRAULIC]: {
		name: 'Гидравлические масла',
		path: 'hydraulic',
		icon: img4
	},
	[INDUSTRIAL]: {
		name: 'Масла индустриальные',
		path: 'industrial',
		icon: img5
	},
	// [SMALL_EQUIPMENT]: {
	// 	name: 'Масла для малоразмерной техники',
	// 	path: 'smallEquipment',
	// 	icon: img7
	// },
	[LIQUIDS]: {
		name: 'Жидкости',
		path: 'liquids',
		icon: img8
	}
}