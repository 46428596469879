import CatalogLink from "@src/components/catalogLink/CatalogLink"
import { categories } from "@src/helpers/categoriesInfo"
import CatalogSubNav from "./CatalogSubNav"

const NavBlock = ({path, text, category, product}) => {

	const to = `/catalog/${path}`
	const isActive = category === path

	return (
		<li>
			<CatalogLink
				{...{to, text, isActive}}
			/>
			{isActive /* && product */ &&
				<CatalogSubNav {...{category, product}} />
			}
		</li>
	)
}

const CatalogNav = ({category, product}) => {

	return (
		<div className="catalog_nav">
			<ul id="catalog_nav">
				{Object.keys(categories).map((key, index) => (
					<NavBlock
						key={`${key}-${index}`}
						path={categories[key].path}
						text={categories[key].name}
						{...{category, product}}
					/>
				))}
			</ul>
		</div>
	)
}

export default CatalogNav