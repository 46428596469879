import './cooperation.css'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import ModalMessage from '../../components/modalMessage/ModalMessage'
import PhoneInput from '@src/components/phoneInput/PhoneInput'

const Cooperation = () => {
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
	const [isMessageFail, setIsMessageFail] = useState(false)
	const [isMessageSuccess, setIsMessageSuccess] = useState(false)
	const [formData, setFormData] = useState({
		name: '',
		phone_number: '',
		company: '',
		city: '',
	})

	const handleCheckboxChange = (e) => {
		setIsCheckboxChecked(e.target.checked)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (isCheckboxChecked) {
			const url = 'https://rusit-po.ru/xim/mail/partner/'

			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				mode: 'cors',
				body: JSON.stringify(formData),
			})
				.then((response) => {
					if (response.ok) {
						setIsMessageSuccess(true)
						setFormData({ name: '', phone_number: '', company: '', city: '' })
					} else {
						setIsMessageFail(true)
					}
				})
				.catch((error) => {
					console.error('Error:', error)
				})
		} else {
			console.log('Please check the checkbox to submit the form.')
		}
	}
	const closeModals = () => {
		setIsMessageFail(false)
		setIsMessageSuccess(false)
	}

	return (
		<>
			<section className="cooperation_map">
				{isMessageSuccess && (
					<ModalMessage
						modalText={['Спасибо!', 'Информация передана успешно. Ожидайте ответа.']}
						closeModals={closeModals}
					/>
				)}
				{isMessageFail && (
					<ModalMessage
						modalText={['Что-то пошло не так.', 'Проверьте подключение к интернету или попробуйте позже']}
						closeModals={closeModals}
					/>
				)}
				<div className="cooperation_map__element">
					<div className="cooperation_text">
						<h2 className="cooperation_map__header">Приглашаем к сотрудничеству</h2>
						<ul className="cooperation_map__list">
							<li>Дистрибьюторов</li>
							<li>Оптовые компании</li>
							<li>Предприятия</li>
						</ul>
					</div>
				</div>
			</section>
			<section className="form_questions">
				<div className="content">
					<h2 className="form_Title">Стать партнером</h2>
					<p className="form_Sub_Title">Мы свяжемся с Вами в самое ближайшее время</p>
					<form onSubmit={handleSubmit}>
						<div className="form_inputs">
							<input required type="text" placeholder="Город" name="city" onChange={handleInputChange} />
							<input required type="text" placeholder="Название компании" name="company" onChange={handleInputChange} />
							<PhoneInput
								onChange={handleInputChange}
								name="phone_number"
								required
							/>
							<input
								required
								type="text"
								placeholder="Как к Вам обращаться?"
								name="name"
								onChange={handleInputChange}
							/>
						</div>
						<button type="submit">Отправить</button>
					</form>
					<div className="politica">
						<label>
							<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
								<div id="checbox_form_after"></div>
							</div>
							<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
							<span className="checbox_form__text">
								Ознакомлен(а) с{' '}
								<Link className="checbox_form__link" to="/politic">
									пользовательским соглашением
								</Link>
							</span>
						</label>
					</div>
				</div>
			</section>
		</>
	)
}

export { Cooperation }
