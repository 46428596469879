import { phoneMask, phonePlaceholder } from '@src/helpers/masks'
import React from 'react'
import MaskedInput from 'react-text-mask'

const PhoneInput = ({onChange, name, required}) => {
	return (
		<MaskedInput
			mask={phoneMask}
			placeholder={phonePlaceholder}
			type="tel"
			{...{onChange, name, required}}
		/>
	)
}

export default PhoneInput