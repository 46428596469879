import { Link } from 'react-router-dom'
import { categories } from '@src/helpers/categoriesInfo'
import './catalog.css'

const CatalogItem = ({path, text, icon}) => {
	return (
		<Link to={`/catalog/${path}`}>
			<div className="catalog_item">
				<img src={icon} alt={text} />
				<span className="catalogItemName">
					{text}
				</span>
			</div>
		</Link>
	)
}

const CatalogHomepage = () => {
	return (
		<section className="catalogHomepage">
			<div className="content">
				<div className="catalog_items">
					{Object.keys(categories).map((key, index) => (
						<CatalogItem
							key={`${key}-${index}`}
							path={categories[key].path}
							text={categories[key].name}
							icon={categories[key].icon}
						/>
					))}
				</div>
			</div>
		</section>
	)
}
export { CatalogHomepage }
