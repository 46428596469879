import { CustomLink } from './../CustomLink'
import logo from './../../img/logo.png'
import MenuIcon from './../../img/icon/menu.svg'
import Foot_scrolTop from './../../img/icon/foot_scrolTop.svg'
import CloseMenu from './../../img/icon/CloseMenu.svg'
import Menufon1 from './../../img/mobile_menu_mini.svg'
import Menufon2 from './../../img/mobile_menu.svg'
import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './../reset.css'
import './header.css'

const Header = () => {
	const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	// Function to toggle the mobile menu
	const openMobileMenu = () => {
		setIsMobileMenuOpen(true)
	}

	// Function to close the mobile menu
	const closeMobileMenu = () => {
		setIsMobileMenuOpen(false)
	}
	return (
		<header className="header">
			<div className="content head_content">
				{isMobileMenuOpen && (
					<div className="mobile_menu">
						{/* <img src={Menufon1} alt="Фон" className="mob_menu_fon" />
						<img src={Menufon2} alt="Фон" className="mob_menu_fon_big" /> */}
						<div className="mobile_menu_header">
							<div className="logo">
								<a href="/">
									<img src={logo} alt="logo" />
								</a>
							</div>
							<div className="close_Mobile_menu" onClick={closeMobileMenu}>
								<img src={CloseMenu} alt="Закрыть меню" />
							</div>
						</div>
						<ul id="nav_mobile">
							<li>
								<CustomLink onClick={closeMobileMenu} to="/">
									Главная
								</CustomLink>
							</li>
							<li>
								<CustomLink onClick={closeMobileMenu} to="/about">
									О компании
								</CustomLink>
							</li>
							<li>
								<CustomLink onClick={closeMobileMenu} to="/catalog/passengerCars">
									Каталог
								</CustomLink>
							</li>
							<li>
								<CustomLink onClick={closeMobileMenu} to="/buy">
									Где купить
								</CustomLink>
							</li>
							<li>
								<CustomLink onClick={closeMobileMenu} to="/contact">
									Контакты{' '}
								</CustomLink>
							</li>
						</ul>
						<Link onClick={closeMobileMenu} to="/cooperation" className="partner_btn btn partner_btn__header">
							Стать партнером
						</Link>
					</div>
				)}
				<div className="header_content">
					<div className="logo">
						<CustomLink to="/">
							<img src={logo} alt="logo" />
						</CustomLink>
					</div>
					<div className="footer_scrolTop">
						<img src={Foot_scrolTop} alt="На верх" className="scrollTop_icon" />
					</div>
					<ul id="nav">
						<li>
							<CustomLink to="/">Главная</CustomLink>
						</li>
						<li>
							<CustomLink to="/about">О компании</CustomLink>
						</li>
						<li>
							<CustomLink to="/catalog/passengerCars">Каталог </CustomLink>
						</li>
						<li>
							<CustomLink to="/buy">Где купить</CustomLink>
						</li>
						<li>
							<CustomLink to="/contact">Контакты </CustomLink>
						</li>
					</ul>
					<div className="menu_links">
						<Link to="/cooperation" className="partner_btn btn">
							Стать партнером
						</Link>
						<div className="open_menu" onClick={openMobileMenu}>
							<img src={MenuIcon} alt="Меню" />
						</div>
					</div>
				</div>
			</div>
		</header>
	)
}

export { Header }
