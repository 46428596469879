import { Link } from "react-router-dom"
import './catalogLink.css'

const CatalogLink = ({to, text, isActive = false}) => {

	let cssClass = 'catalog-link'

	if (isActive)
		cssClass += ' catalog-link_selected'

	return (
		<Link
			className={cssClass}
			{...{to}}
		>
			{text}
		</Link>
	)
}

export default CatalogLink