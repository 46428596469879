import * as product from './products'
import * as category from './categories'

export const productsByCategories = {
	[category.PASSENGER_CARS]: [
		[product.XIM_2T_MOTOR],
		[product.XIM_4T_MOTOR]
		// [product.XIM_XWAY_ULTRA],
		// // [product.XIM_XWAY_PROFESSIONAL],
		// [product.XIM_XWAY_LUXE],
		// [product.XIM_XWAY_EXTRA],
		// // [product.XIM_XWAY_ASIA],
		// [product.XIM_XWAY_ULTRA_PLUS],
		// [product.XIM_XWAY_EXTRA_PLUS_PAO]
	],
	[category.COMMERCIAL_VEHICLES]: [
		[product.XIM_XTRUCK_PRO],
		[product.XIM_XTRUCK_SYNTHETIC],
		[product.XIM_XTRUCK_SYNTHETIC_PRO],
		[product.XIM_XTRUCK_SYNTHETIC_DPF],
		[product.XIM_XTRUCK_SYNTHETIC_PAO],
		[product.XIM_XTRUCK_EURO3],
		[product.XIM_XTRUCK_EURO2],
		// [product.XIM_SAE],
		[product.XIM_M_8B],
		[product.XIM_M_8G2K_M_10G2K],
		[product.XIM_M_8DM_M_10DM]
		// [product.XIM_M_8BD]
	],
	[category.GAS_ENGINES_OILS]: [
		[product.XIM_XGAS_LD5000_PAO],
		[product.XIM_XGAS_LD5000_SYNT]
	],
	[category.TRANSMISSION_OILS]: [
		[product.XIM_CVT_FLUID],
		[product.XIM_ATF_PAO_MULTI],
		[product.XIM_ATF_SYNTHETIC_MULTI],
		[product.XIM_ATF_DEXRON_VI_PAO],
		[product.XIM_ATF_DEXRON_III],
		[product.XIM_ATF_DEXRON_II],
		// SYNT???

		[product.XIM_TRANSMISSION_PAO_75W_90_API_GL_5],
		[product.XIM_TRANSMISSION_SYNT_75W_90_85W_90_LS_API_GL_5],
		[product.XIM_TRANSMISSION_SYNT_75W_90_API_GL_5],
		[product.XIM_TRANSMISSION_PAO_LS_75W_140_API_GL_5],
		[product.XIM_TRANSMISSION_80W_90_85W_90_API_GL_5],
		[product.XIM_TRANSMISSION_80W_140_85W140_API_GL_5],

		[product.XIM_TRANSMISSION_PAO_75W_90_API_GL_4_5],
		[product.XIM_TRANSMISSION_SYNT_75W_90_API_GL_4_5],
		[product.XIM_TRANSMISSION_75W_90_API_GL_4_5],
		[product.XIM_TRANSMISSION_80W_90_85W_90_API_GL_4_5],

		[product.XIM_TRANSMISSION_HYD_UTTO_10W30_API_GL_4],
		[product.XIM_TRANSMISSION_75W_85_API_GL_4],
		[product.XIM_TRANSMISSION_75W_90_API_GL_4],
		[product.XIM_TRANSMISSION_80W90_85W_90_API_GL_4],
		[product.XIM_TRANSMISSION_HYD],
		[product.XIM_TRANSMISSION_SYNT_75W_90_API_GL_4],

		[product.XIM_TAD17I],
	],
	[category.GUR]: [
		[product.XIM_PS_CHF]
	],
	[category.HYDRAULIC]: [

		// [product.XIM_AU],
		// [product.XIM_MG_8A],

		// [product.XIM_IGP]

		// [product.XIM_I_12A],
		// [product.XIM_I_8A],

		// [product.XIM_HYDRAULIC_PRO],
		// [product.XIM_HYDRAULIC_PRO_PAO],

		[product.XIM_HYDRAULIC_SE_HLP],
		[product.XIM_HYDRAULIC_HLP],
		[product.XIM_HYDRAULIC_ZF_HLP],
		[product.XIM_HYDRAULIC_SE_HVLP],
		[product.XIM_HYDRAULIC_HVLP],
		[product.XIM_HYDRAULIC_ZF_HVLP],
		[product.XIM_HYDRAULIC_HVLP_ARCTIC_PAO],
		[product.XIM_HYDRAULIC_HVLP_PRO_PAO],
		[product.XIM_HYDRAULIC_ZF_HVLP_PRO_PAO],

		[product.XIM_VMGZ_45],
		[product.XIM_VMGZ_55],
		[product.XIM_VMGZ_60_PAO],

		[product.XIM_MGE_46B],
		[product.XIM_AU],
		[product.XIM_MARKA_A],
		// [product.XIM_MARKA_P],

		[product.XIM_MGT],

		[product.XIM_IGP_18_IGP_30_IGP_38_IGP_49],
		[product.XIM_IGP_72_IGP_91_IGP_114],

	],
	[category.INDUSTRIAL]: [

		[product.XIM_REDUCTOR_OIL_CLP],
		[product.XIM_REDUCTOR_OIL_CLP_SYNTHETIC],
		[product.XIM_REDUCTOR_OIL_SVG_PAO],
		[product.XIM_REDUCTOR_OIL_CLPF],

		[product.XIM_CIRCULATION],

		[product.XIM_SLIDE_WAY],
		[product.XIM_SLIDE_WAY_SYNT],

		[product.XIM_FORMA],

		[product.XIM_COMOR_VDL],
		[product.XIM_COMOR_VDL_ARCTIC_PAO],
		[product.XIM_COMOR_VDL_TERMO_PAO],

		[product.XIM_I_20A_I_30A_I_40A_I_50A]
	],
	// [category.REDUCTOR]: [
	// 	// [product.XIM_REDUCTOR_OIL_SVG_PAO],
	// ],
	// [category.SMALL_EQUIPMENT]: [
	// 	// [product.XIM_4T_SPORT_MOTOR],
	// ],
	// [category.CIRCULATION]: [
	// 	[product.XIM_CIRCULATION],
	// ],
	[category.LIQUIDS]: [
		[product.XIM_XCOOL_G11_40_65],
		[product.XIM_XCOOL_G12_40_65],
		[product.XIM_TOSOL_40_65],
		[product.XIM_XCLEAN_10],
		[product.XIM_XCLEAN_20],
		[product.XIM_XCLEAN_30],
		[product.XIM_XCLEAN_MOSQUIT]
	]
}