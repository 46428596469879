import './buy.css'
// import buy1 from './../../img/buy1.png'
// import buy2 from './../../img/buy2.png'
// import buy3 from './../../img/buy3.png'
const Buy = () => {
	return (
		<>
			<section className="buy">
				<div className="content">
					<h1>Где купить продукцию XIM</h1>
					<p className='buy-paragraph'>Вы можете приобрести нашу продукцию через форму обратной связи.</p>
					{/* <div className="buy_items">
						<a href="https://www.ozon.ru/seller/xim-980835/products/?miniapp=seller_980835">
							<div className="buy_item">
								<img src={buy1} alt="Озон" />
							</div>
						</a>
						<a href="https://www.wildberries.ru/seller/284811">
							<div className="buy_item">
								<img src={buy2} alt="wildberries" />
							</div>
						</a>
					</div> */}
				</div>
			</section>
		</>
	)
}

export { Buy }
