import React, { useState } from 'react'
import './formHomepage.css'
import ModalMessage from '../modalMessage/ModalMessage'
import { Link } from 'react-router-dom'
import PhoneInput from '../phoneInput/PhoneInput'

const FormHome = () => {
	const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
	const [isMessageFail, setIsMessageFail] = useState(false)
	const [isMessageSuccess, setIsMessageSuccess] = useState(false)
	const [formData, setFormData] = useState({
		name: '',
		phone_number: '',
	})

	const handleCheckboxChange = (e) => {
		setIsCheckboxChecked(e.target.checked)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		const url = 'https://rusit-po.ru/xim/mail/write-us/'

		if (isCheckboxChecked) {
			fetch(url, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(formData),
			})
				.then((response) => {
					if (response.ok) {
						setIsMessageSuccess(true)
						setFormData({ name: '', phone_number: '' })
					} else {
						setIsMessageFail(true)
					}
				})
				.catch((error) => {
					console.error('An error occurred while submitting form data:', error)
				})
		} else {
			console.log('Please check the checkbox to submit the form.')
		}
	}

	const closeModals = () => {
		setIsMessageFail(false)
		setIsMessageSuccess(false)
	}

	return (
		<section className="form_home">
			{isMessageSuccess && (
				<ModalMessage
					modalText={['Спасибо!', 'Информация передана успешно. Ожидайте ответа.']}
					closeModals={closeModals}
				/>
			)}
			{isMessageFail && (
				<ModalMessage
					modalText={['Что-то пошло не так.', 'Проверьте подключение к интернету или попробуйте позже']}
					closeModals={closeModals}
				/>
			)}
			<div className="content">
				<h2 className="form_title">Напиши нам</h2>
				<p className="formSubTitle">Мы свяжемся с Вами в самое ближайшее время</p>
				<form id="form_home" className="form_homePage" onSubmit={handleSubmit}>
					<input required type="text" name="name" placeholder="Как к Вам обращаться?" onChange={handleInputChange} />
					<PhoneInput
						onChange={handleInputChange}
						name="phone_number"
						required
					/>
					<input type="submit" value="Отправить" />
				</form>
				<div className="politica">
					<label>
						<div className={`checbox_form ${isCheckboxChecked ? 'checbox_form_active' : ''}`}>
							<div id="checbox_form_after"></div>
						</div>
						<input type="checkbox" id="checbox_politic" onChange={handleCheckboxChange} required />
						<span className="checbox_form__text">
							Ознакомлен(а) с{' '}
							<Link className="checbox_form__link" to="/politic">
								пользовательским соглашением
							</Link>
						</span>
					</label>
				</div>
			</div>
		</section>
	)
}

export { FormHome }
